section.landing-page {
  position: relative;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 15px;
}
section.landing-page .card {
  overflow-x: hidden;
}
section.landing-page .mui-stepper-container {
  width: calc(100% + 40px);
  margin-left: -20px;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.0745098039);
}
section.landing-page .landing-page__body {
  position: relative;
  margin: 0 auto;
}
section.landing-page .landing-page__body .wizard-content-area {
  margin-top: 20px !important;
}
section.landing-page .landing-page__body form {
  margin-top: 15px;
}
section.landing-page .landing-page__body button#form-submit {
  margin-top: 30px;
}
section.landing-page .landing-page__body .existing-user-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  border-top: 1px solid rgba(128, 128, 128, 0.2509803922);
  padding-top: 30px;
}
section.landing-page .landing-page__body .existing-user-container button {
  margin-left: 5px;
}
section.landing-page .landing-page__body .existing-user-container .underline-ghost-button {
  background-color: transparent;
  color: #235685;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  section.landing-page {
    padding: 15px 10px;
  }
  section.landing-page .header-content h1 {
    font-size: 24px;
    line-height: 2rem;
  }
  section.landing-page .header-content p {
    font-size: 0.8rem;
    line-height: 22px;
    margin-top: 5px;
  }
  section.landing-page .landing-page__body {
    padding: 0 !important;
  }
}/*# sourceMappingURL=LandingPage.css.map */