#flat-iron-logo.logo-wrapper {
  width: 120px;
  display: flex;
  justify-content: center;
}
#flat-iron-logo.logo-wrapper svg {
  height: 100%;
  width: 100%;
}
#flat-iron-logo.logo-wrapper img {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  #flat-iron-logo.logo-wrapper {
    width: 105px;
  }
}/*# sourceMappingURL=FlatIronLogo.css.map */