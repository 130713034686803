.MuiSnackbar-root#snackbar-alert {
  box-shadow: var(--bs);
  border-left: 4px solid green;
}
.MuiSnackbar-root#snackbar-alert svg {
  color: --black;
}
.MuiSnackbar-root#snackbar-alert.warning {
  border-left: 4px solid #ed6c02;
}
.MuiSnackbar-root#snackbar-alert.error {
  border-left: 4px solid #d32f2f;
}
.MuiSnackbar-root#snackbar-alert.info {
  border-left: 4px solid #0288d1;
}/*# sourceMappingURL=SnackbarAlert.css.map */