#form-signature {
  position: relative;
}
#form-signature #signature-wrapper {
  position: relative;
  width: 100%;
  height: 160px;
}
#form-signature #signature-wrapper canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
#form-signature #signature-wrapper .signature-line {
  background-color: #bbbbbb;
  height: 2px;
  position: absolute;
  bottom: 35px;
  left: 50%;
  width: 90%;
  transform: translateX(-50%);
}
#form-signature #signature-wrapper .signature-line .cross {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0px;
}
#form-signature #signature-wrapper .signature-line .cross svg {
  height: 28px;
  width: 28px;
  fill: #bbbbbb;
}
#form-signature .clear-signature-button {
  display: flex;
  justify-content: flex-end;
}
#form-signature .clear-signature-button #clear {
  padding: 2px;
  text-decoration: underline;
  font-size: 0.7rem;
}/*# sourceMappingURL=Signature.css.map */