.multiple-item-card-outer-wrapper {
  position: relative;
  border-bottom: none;
  &.additional {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 2px dashed #1c456ac2;
    // border-top: 2px dashed #1c456ac2;
    // margin: 25px -15px 0;
    // padding: 25px 15px 0;
  }
  article.multiple-item-card {
    position: relative;
    border: 1px dotted #80808061;
    // padding: 10px 20px;
    border-radius: 8px;
    margin-bottom: 15px;

    #all-fields-accordian {
      .Mui-expanded {
        min-height: unset;
        .MuiAccordionSummary-content {
          margin: 12px 0;
        }
      }
      .MuiAccordionSummary-root {
        position: relative;
        // padding: 15px 20px 10px;
        // padding-top: 5px;
        background: #1c456a14;
      }
      .multiple-item-card__header {
        // position: relative;
        // padding: 15px 20px 10px;
        // background: #1c456a14;
        // margin: -10px -20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header__title {
          display: flex;
          align-items: center;
          font-size: 0.9rem;
          font-weight: 700;
          line-height: 1.2rem;
          color: #215282;
          h3 {
            text-align: left;
            font-weight: inherit;
            line-height: inherit;
            font-size: inherit;
            white-space: normal;
            text-overflow: unset;
            overflow: unset;
          }
          .index-value {
            margin-left: 8px;
            line-height: inherit;
            font-size: inherit;
            font-weight: inherit;
            font-family: var(--mont) !important;
          }
        }
        button.remove {
          border: none;
          // border: 1px solid var(--blue);
          color: var(--blue);
          border: 1px solid var(--blue);
          font-size: 0.7rem;
          font-weight: 600;
          padding: 2px 10px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          margin-left: 10px;
          z-index: 1111111111;
        }
      }
    }
    //Card header
    .multiple-item-card__body {
      position: relative;
      // margin-top: 15px;
      .static-form-label > label {
        font-weight: 500;
        // font-size: 0.75rem;
      }
    }
  }
}
