.w4rAnimated_checkmark {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w4rAnimated_checkmark .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.w4rAnimated_checkmark .path.circle {
  animation: dash 0.9s ease-in-out;
}

.w4rAnimated_checkmark .path.line {
  stroke-dashoffset: 1000;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}

.w4rAnimated_checkmark .path.check {
  stroke-dashoffset: -100;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
.animation-ctn {
  text-align: center;
  margin-top: 5em;
}
@keyframes checkmark {
  0% {
    stroke-dashoffset: 100px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
@keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 480px;
  }
  100% {
    stroke-dashoffset: 960px;
  }
}
@keyframes colored-circle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}
/* other styles */
/* .svg svg {
    display: none
}
 */
.inlinesvg .svg svg {
  display: inline;
}

/* .svg img {
    display: none
} */
.icon--order-success svg polyline {
  animation: checkmark 0.25s ease-in-out 0.7s backwards;
}

.icon--order-success svg circle {
  animation: checkmark-circle 0.6s ease-in-out backwards;
}

.icon--order-success svg circle#colored {
  animation: colored-circle 0.6s ease-in-out 0.7s backwards;
}/*# sourceMappingURL=AnimatedCheckmark.css.map */