* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  scroll-behavior: smooth;
}

:root {
  --pageMaxWidth: 940px;
  --portalMaxWidth: 1540px;
  --headerHeight: 70px;
  --logoHeight: 80px;
  --verticalSideNavWidth: 190px;
  --inter: "Inter", sans-serif;
  --mont: "Montserrat", sans-serif;
  --jost: "Jost", sans-serif;
  --script: "Meow Script", cursive;
  --primary: #235685;
  --blueGradient: linear-gradient(
    45deg,
    #285b8a 0%,
    #21517d 33%,
    #235380 50%,
    #25537e 67%,
    #2d577c 100%
  );
  --bs: 0px 1px 2px 1px rgba(0, 0, 0, 0.12);
  --bs2: 0 0 1px rgba(9, 30, 66, 0.31), 0 20px 32px -8px rgba(9, 30, 66, 0.25);
  --black: #444d56;
  // --black: #000000e6;
  --red: #d32f2f;
  --lightBlue: #f2f5f8;
  --blue: #1c456a;
  --blueButton: #235685;
  --blueHover: #396791;
  --blueLightHover: #356899;
  --green: #4da177;
  --white: #ffffff;
  --gray: #616c70;
  --border: 1px solid #0000001f;
  // --borderGray: #c1c0c0;
  --borderGray: #bebdbd57;
  --darkGray: #777676;
  --lightGray: #e1e1e1;
  --bgGray: #80808024;
  --buttonPadding: 8px 16px;
}

button,
body,
p,
input,
div,
span,
textarea {
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-family: var(--inter) !important;
  color: inherit;
  -webkit-font-smoothing: antialiased;
}

body,
p,
input,
select,
textarea {
  line-height: 1.4rem;
  // background-color: #ffffff !important;
}

.MuiModal-root {
  // z-index: 1111111111 !important;
  z-index: 1999999999 !important;
}

body,
p {
  color: #242526;
}

h1 {
  font-family: var(--mont);
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
}

h2 {
  font-family: var(--mont);
  -webkit-font-smoothing: antialiased;
  font-size: 22px;
}

h3 {
  font-family: var(--mont);

  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  font-weight: 600;
  // font-weight: 400;
}

.MuiSvgIcon-root {
  font-size: 1.25rem;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-vertical {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.two-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

button {
  border: none;
  cursor: pointer;
  font-weight: 600;
  transition: 150ms;
  border-radius: 2px;
  font-size: 0.8rem;
  padding: var(--buttonPadding);
  background-color: transparent;
  &.no-border {
    border-color: transparent;
  }
  span {
    font-size: inherit;
  }
  svg {
    transition: 150ms;
  }
  &:hover {
    background-color: #f4f6f9;
  }
  .icon-wrapper {
    svg {
      display: block;
      height: 16px;
    }
  }
}

.primary-button {
  background-color: var(--blue);
  color: #ffffff;
  &:hover {
    background-color: var(--blueHover);
  }
}

//Blue buttons
.btn-blue {
  background-color: var(--blueButton);
  color: #ffffff;
  border: 1px solid var(--blueButton);
  svg {
    fill: #ffffff;
  }
  &:hover {
    background-color: var(--blueHover);
  }
}
.btn-gray {
  padding: var(--buttonPadding);
  background-color: transparent;
  // border: 1px solid var(--gray);
  color: var(--gray);
  svg {
    fill: var(--gray);
  }
  &:hover {
    color: #ffffff;
    background-color: var(--blueHover);
    svg {
      fill: #ffffff;
    }
  }
}

.btn-blue--ghost {
  padding: var(--buttonPadding);
  background-color: transparent;
  border: 1px solid var(--blueButton);
  color: var(--blueButton);
  svg {
    fill: var(--blueButton);
  }
  &:hover {
    color: #ffffff;
    background-color: var(--blueHover);
    svg {
      fill: #ffffff;
    }
  }
}
.btn-gray--ghost {
  padding: var(--buttonPadding);
  background-color: transparent;
  border: 1px solid var(--darkGray);
  color: var(--darkGray);
  svg {
    fill: var(--darkGray);
  }
  &:hover {
    color: #ffffff;
    background-color: var(--darkGray);
    svg {
      fill: #ffffff;
    }
  }
}

.btn-blue--link {
  padding: var(--buttonPadding);
  display: flex;
  color: var(--blue);
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  text-decoration: underline;
  svg {
    fill: var(--blue);
  }
  &:hover {
    color: var(--blueButton);
    background-color: transparent;
  }
}

//White Buttons
.btn-white {
  padding: var(--buttonPadding);
  background-color: var(--white);
  border: 1px solid var(--white);
  color: var(--blue);
  svg {
    fill: var(--blue);
  }
  &:hover {
    background-color: rgba(233, 238, 243, 0.975);
    // background-color: #e9eef3;
  }
}

.btn-white--ghost {
  padding: var(--buttonPadding);
  color: var(--white);
  border: 1px solid var(--white);
  background-color: transparent;
  svg {
    fill: var(--white);
  }
  &:hover {
    background-color: rgba(233, 238, 243, 0.975);
    color: var(--blue);
    svg {
      fill: var(--blue);
    }
  }
}

.ghost-button {
  background-color: #ffffff;
  color: var(--blue);
  border: 1px solid var(--blue);
  &:hover {
    background-color: #f4f7f8;
  }
}

// table {
//   thead {
//     position: sticky;
//     top: -4px;
//     z-index: 111;
//   }
//   .table-title {
//     padding: 10px;
//     th {
//       font-family: var(--mont);
//       padding: 10px 10px;
//       // padding: 24px 15px;
//       font-size: 1rem !important;
//       font-weight: 600;
//       // font-weight: 800;
//       color: #235685;
//     }
//   }
//   .cell-titles {
//     background-color: #f0f2f4;
//     border-top: 1px solid rgba(175, 175, 175, 0.226);
//     border-bottom: 1px solid rgba(175, 175, 175, 0.226);
//   }
// }

//Mobile breakpoint
@media only screen and (max-width: 600px) {
  :root {
    --headerHeight: 55px;
  }

  .two-col {
    display: grid;
    grid-template-columns: 1fr;
  }
}
