#radio-field {
  position: relative;
  text-align: left;
}
#radio-field .radio-field-options.vertical {
  display: flex;
  flex-direction: column;
}
#radio-field .MuiTypography-root {
  font-size: 0.75rem !important;
  margin-left: -5px;
  color: #666666;
}
.MuiRadio-root.Mui-checked {
  color: #235685 !important;
}/*# sourceMappingURL=RadioField.css.map */