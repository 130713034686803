.pretty-json-container {
  div,
  span {
    font-size: 0.65rem;
    line-height: 0.8rem;
  }

  svg {
    font-size: 0.7rem !important;
  }
}
