.success-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.success-content .success-message {
  margin-top: 15px;
}
.success-content span {
  font-weight: bold;
}
.success-content .checkmark {
  width: 72px !important;
}/*# sourceMappingURL=SuccessContent.css.map */