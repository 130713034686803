.multiple-item-card-outer-wrapper {
  position: relative;
  border-bottom: none;
}
.multiple-item-card-outer-wrapper.additional {
  margin-top: 25px;
  padding-top: 25px;
  border-top: 2px dashed rgba(28, 69, 106, 0.7607843137);
}
.multiple-item-card-outer-wrapper article.multiple-item-card {
  position: relative;
  border: 1px dotted rgba(128, 128, 128, 0.3803921569);
  border-radius: 8px;
  margin-bottom: 15px;
}
.multiple-item-card-outer-wrapper article.multiple-item-card #all-fields-accordian .Mui-expanded {
  min-height: unset;
}
.multiple-item-card-outer-wrapper article.multiple-item-card #all-fields-accordian .Mui-expanded .MuiAccordionSummary-content {
  margin: 12px 0;
}
.multiple-item-card-outer-wrapper article.multiple-item-card #all-fields-accordian .MuiAccordionSummary-root {
  position: relative;
  background: rgba(28, 69, 106, 0.0784313725);
}
.multiple-item-card-outer-wrapper article.multiple-item-card #all-fields-accordian .multiple-item-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.multiple-item-card-outer-wrapper article.multiple-item-card #all-fields-accordian .multiple-item-card__header .header__title {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.2rem;
  color: #215282;
}
.multiple-item-card-outer-wrapper article.multiple-item-card #all-fields-accordian .multiple-item-card__header .header__title h3 {
  text-align: left;
  font-weight: inherit;
  line-height: inherit;
  font-size: inherit;
  white-space: normal;
  text-overflow: unset;
  overflow: unset;
}
.multiple-item-card-outer-wrapper article.multiple-item-card #all-fields-accordian .multiple-item-card__header .header__title .index-value {
  margin-left: 8px;
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-family: var(--mont) !important;
}
.multiple-item-card-outer-wrapper article.multiple-item-card #all-fields-accordian .multiple-item-card__header button.remove {
  border: none;
  color: var(--blue);
  border: 1px solid var(--blue);
  font-size: 0.7rem;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  z-index: 1111111111;
}
.multiple-item-card-outer-wrapper article.multiple-item-card .multiple-item-card__body {
  position: relative;
}
.multiple-item-card-outer-wrapper article.multiple-item-card .multiple-item-card__body .static-form-label > label {
  font-weight: 500;
}/*# sourceMappingURL=MultipleItemCard.css.map */