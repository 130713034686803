#file-upload-field .MuiInputLabel-root {
  font-weight: 500;
}

#file-upload .file-upload-inner-content {
  display: flex;
  width: 100%;
  text-align: center;
}
#file-upload .file-upload-inner-content .file-upload-box {
  position: relative;
  display: flex;
  flex-grow: 1;
  border-radius: 2px;
  background-color: rgba(247, 247, 247, 0.6392156863);
  border: 2px dashed #d4d4d4;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 200ms;
  color: var(--blue);
  width: 100%;
  height: 100px;
  cursor: pointer;
  min-width: 0;
}
#file-upload .file-upload-inner-content .file-upload-box:hover {
  background-color: #e3eef8;
}
#file-upload .file-upload-inner-content .file-upload-box svg {
  fill: var(--gray);
  height: 20px;
  width: 20px;
}
#file-upload .file-upload-inner-content .file-upload__title {
  color: var(--gray);
  font-size: 0.68rem;
}/*# sourceMappingURL=FileUpload.css.map */