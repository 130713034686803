#address-autofill {
  position: relative;
  z-index: 111111111111;
}
#address-autofill .autofill-input-wrapper.required-error .css-1wa3eu0-placeholder {
  color: var(--red);
}
#address-autofill .css-2b097c-container > div {
  min-height: 45px !important;
}
#address-autofill .css-2613qy-menu {
  position: relative;
  display: block;
  z-index: 111111111111 !important;
}
#address-autofill .autofill-input-wrapper {
  cursor: pointer;
}
#address-autofill .button-container {
  position: relative;
  font-weight: 500;
  transition: 100ms;
}
#address-autofill .button-container button {
  display: flex;
  font-size: 0.75rem;
  font-weight: inherit;
  border-radius: 0;
  padding: 0;
  color: var(--blue);
}
#address-autofill .button-container button svg {
  fill: var(--blue);
}
#address-autofill .button-container#clear {
  text-decoration: underline;
  margin-top: 10px;
}
#address-autofill .button-container#custom-address {
  position: absolute;
  right: 0;
  top: 6px;
}
#address-autofill .button-container#custom-address button {
  text-decoration: underline;
}
#address-autofill .MuiPaper-root {
  margin-top: 0px !important;
}
#address-autofill #all-fields-accordian {
  position: relative;
  box-shadow: none;
  border: 1px solid;
  border-radius: 4px;
  border-color: hsl(0, 0%, 80%);
  overflow: hidden;
}
#address-autofill #all-fields-accordian .MuiCollapse-wrapper {
  border-top: 2px solid rgba(28, 69, 106, 0.2509803922);
}
#address-autofill #all-fields-accordian .MuiAccordionSummary-root {
  min-height: 44px;
  background-color: #ffffff;
}
#address-autofill #all-fields-accordian .MuiAccordionSummary-content {
  margin: 10px 0;
}
#address-autofill #all-fields-accordian .MuiFormHelperText-root {
  color: var(--red);
  font-size: 0.7rem;
  margin-top: 0;
  margin-left: 0;
  line-height: 0.8rem;
  font-style: italic;
}
#address-autofill #all-fields-accordian .MuiAccordionDetails-root {
  background: rgba(35, 86, 133, 0.0117647059);
}

.all-fields-container {
  position: relative;
  background: rgba(128, 128, 128, 0.0705882353);
  padding: 15px 5px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.all-fields-container input {
  background-color: #ffffff;
}

.pac-container {
  z-index: 1111111111111111100000;
}/*# sourceMappingURL=AddressAutofillField.css.map */