.loader-wrapper {
  position: relative;
  height: 150px;
  svg {
    width: 100% !important;
    height: 100% !important;
  }
  .inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11111;
    // background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    flex-direction: column;
  }
  .message {
    margin-top: 15px;
  }
}
