.multiple-unit-card-item {
  position: relative;
  border-bottom: none;
  &#property-unit {
    .multiple-item-card {
      border: 2px solid #1c456a36;
    }
    .MuiAccordionSummary-content {
      margin: 8px 0 !important;
    }
    .MuiAccordionDetails-root {
      background-color: #1c456a05;
    }
    .file-upload-box {
      background-color: #ffffff;
    }
  }
  &.additional {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1.7px dashed #272a2c33;
    // border-top: 1px dashed #1c456ac2;
    // border-top: 2px dashed #1c456ac2;
    // margin: 25px -15px 0;
    // padding: 25px 15px 0;
  }
  article.multiple-item-card {
    position: relative;
    border: 1.5px dotted #80808061;
    // padding: 10px 20px;
    border-radius: 8px;
    margin-bottom: 15px;
    overflow: hidden;
    border-left: 2px solid var(--blue);
    //Card header
    #property-unit-all-fields-accordian {
      .MuiAccordionSummary-root {
        background-color: #ffffff;
        position: relative;
        min-height: 42px;
        border-bottom: 1px solid #1c456a36;
      }
      .MuiAccordionSummary-content {
        margin: 10px 0;
      }
      .MuiAccordionDetails-root {
        padding-top: 0;
      }
      .multiple-item-card__header {
        // position: relative;
        // padding: 15px 20px 10px;
        // background: #1c456a14;
        // margin: -10px -20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        // ==

        .header__title {
          display: flex;
          align-items: center;
          text-align: left;
          font-family: var(--inter);
          h4 {
            span {
              color: #0d0d0dc5 !important;
              // font-weight: 500 !important;
              font-family: inherit;
              line-height: 1.2rem !important;
              font-size: 0.75rem !important;
            }
          }
          .index-value {
            font-family: inherit !important;
          }
        }
        button.remove {
          border: none;
          // border: 1px solid var(--blue);
          color: var(--blue);
          border: 1px solid var(--blue);
          font-size: 0.7rem;
          font-weight: 600;
          padding: 2px 10px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          margin-left: 10px;
          z-index: 1111111111;
        }
      }
      .multiple-item-card__body {
        position: relative;
        margin-top: 0px !important;
        // border-top: 1px solid #8080803b;
        padding-top: 10px;
        .static-form-label > label {
          font-weight: 500;
          font-size: 0.75rem;
        }
      }
    }
  }
}
