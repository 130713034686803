.form-field {
  position: relative;
  width: 100%;
  text-align: left;
}
.form-field .MuiFormControl-root {
  position: relative;
  z-index: 5;
}
.form-field .MuiInputBase-root:hover:not(.Mui-disabled):before {
  border-color: rgba(35, 86, 133, 0.6196078431);
}
.form-field.modified .MuiFormControl-root:after {
  content: "Modified";
  border-top-right-radius: 4px;
  border-bottom-left-radius: 6px;
  position: absolute;
  background: rgba(184, 156, 29, 0.1019607843);
  top: 0;
  font-weight: 600;
  right: 0;
  color: #b89b1d;
  font-size: 10px;
  line-height: 10px;
  padding: 1px 4px;
}
.form-field .MuiFormHelperText-root {
  margin-left: 6px;
  margin-bottom: 8px;
}
.form-field.vertical .MuiFormHelperText-root {
  margin-top: -5px;
}
.form-field.no-side-padding {
  padding-left: 0;
  padding-right: 0;
}
.form-field.no-padding {
  padding: 0;
}
.form-field .MuiSvgIcon-root {
  width: 0.9em;
  height: 0.9em;
  margin-left: -4px;
}
.form-field .MuiFilledInput-root .MuiInputAdornment-root {
  margin-right: 0px;
}
.form-field#text-area-field-component {
  padding: 0 !important;
}
.form-field#text-area-field-component .MuiFilledInput-root {
  padding: 0 !important;
}
.form-field#text-area-field-component .MuiFilledInput-root textarea {
  padding: 22px 10px 10px;
}
.form-field.display-field {
  padding-left: 0;
  padding-right: 0;
}
.form-field .error {
  color: var(--red);
  border-color: var(--red) !important;
  font-size: 0.7rem !important;
  right: 5px;
  bottom: 0px;
}
.form-field .Mui-disabled {
  font-size: 0.8rem;
  border: none;
  -webkit-text-fill-color: #000000 !important;
}
.form-field .file-thumbail-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #d2d2d2;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.form-field .file-thumbail-container .file-thumbnail-path {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-field .file-thumbail-container .file-thumbnail-path span {
  font-size: 0.7rem;
  padding: 8px !important;
}
.form-field .file-thumbail-container .file-thumbnail-path button {
  padding: 8px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-field .file-thumbail-container .file-thumbnail-path button svg {
  display: block;
  color: var(--blue);
  height: 18px;
  width: 18px;
}
.form-field .custom-required-input::after {
  border-bottom: 2px solid #153450;
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
  border-radius: 4px;
  border-color: transparent;
}
.form-field .custom-required-input.required-error {
  color: #d32f2f !important;
}
.form-field .custom-required-input.required-error::after {
  border-bottom-color: #d32f2f;
  transform: scaleX(1);
}
.form-field.slim {
  position: relative;
  display: flex;
  align-items: center;
}
.form-field.slim .MuiFilledInput-input {
  padding-left: 4px;
  font-size: 0.7rem;
  padding: 4px;
  font-weight: 600;
}
.form-field.slim .MuiInputAdornment-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
  color: var(--black);
}
.form-field.slim.editing {
  border: none;
}
.form-field.slim.editing .MuiInput-root {
  border-width: 1px;
}
.form-field.slim.editing .MuiInput-root .MuiInput-input {
  font-weight: normal;
}
.form-field.slim input {
  padding: 4px;
}
.form-field.slim span {
  font-size: 0.7rem;
}
.form-field.slim .drilldown-item {
  position: relative;
}
.form-field.slim .drilldown-item .drilldown-item__label {
  opacity: 0.95;
}
.form-field.slim .drilldown-item .drilldown-item__value {
  font-weight: 600;
  margin-left: 3px;
}
.form-field.slim .drilldown-item.vertical {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
}
.form-field.slim .drilldown-item.vertical .drilldown-item__value {
  margin-left: 0px;
}
.form-field.slim .drilldown-item.fixed-value {
  background-color: gray;
}
.form-field.slim .drilldown-item.editing {
  border: none;
}
.form-field.slim .drilldown-item.editing .MuiInput-root {
  border-width: 1px;
}
.form-field.slim .drilldown-item.editing .MuiInput-root .MuiInput-input {
  font-weight: normal;
}
.form-field.slim fieldset {
  display: none;
}
.form-field.slim .MuiInput-root {
  border-width: 0px;
}
.form-field.slim .MuiInput-root .MuiInput-input {
  padding-left: 4px;
  font-size: 0.7rem;
  padding: 4px;
  font-weight: 600;
}
.form-field.slim .Mui-disabled {
  font-weight: 600;
  font-size: 0.7rem;
  border: none;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.6196078431) !important;
}

.MuiFormControl-root .MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.8274509804) !important;
}

.MuiInputBase-root-MuiFilledInput-root:before {
  border-radius: 0px !important;
}/*# sourceMappingURL=FormField.css.map */