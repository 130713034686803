.display-data-field-item {
  display: flex;
  flex-direction: column;
  font-size: 0.7rem !important;
}
.display-data-field-item.large {
  font-size: 0.8rem !important;
}
.display-data-field-item.large span {
  line-height: 1.2rem;
}
.display-data-field-item.large .display-data-field-item__value {
  padding-left: 2px;
}
.display-data-field-item.row {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.display-data-field-item.row .display-data-field-item__value {
  margin-top: 0;
  margin-left: 4px;
}
.display-data-field-item#primary-item {
  flex-direction: row;
  padding: 0px 10px 0 6px;
  font-size: 0.9rem !important;
}
.display-data-field-item#primary-item span {
  font-family: var(--mont) !important;
  margin-top: 0px;
}
.display-data-field-item#primary-item span.display-data-field-item__label {
  margin-right: 4px;
}
.display-data-field-item span {
  font-size: 0.7rem;
  font-family: inherit;
  font-size: inherit;
  color: #242526;
}
.display-data-field-item span.display-data-field-item__label {
  font-weight: 500;
  white-space: nowrap;
}
.display-data-field-item span.display-data-field-item__value {
  font-weight: 700;
}
.display-data-field-item .MuiChip-root {
  height: 22px;
}
.display-data-field-item .MuiChip-root .MuiChip-label {
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 0.8rem;
  padding-left: 6px;
}
.display-data-field-item .MuiChip-root .MuiChip-deleteIconSmall {
  margin-right: 1px;
}
.display-data-field-item #add.MuiIconButton-root {
  padding: 5px;
  margin-left: 5px;
}/*# sourceMappingURL=DisplayDataFieldItem.css.map */