.text-overflow-container {
  position: relative;
  max-height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  overflow: hidden;
  border-radius: 4px;
  text-align: left;
}
.text-overflow-container .overflow-inner-wrapper {
  padding: 12px 10px;
  max-height: inherit;
  overflow-y: auto;
}
.text-overflow-container .overflow-inner-wrapper p {
  margin-bottom: 10px;
  font-size: 0.7rem;
  line-height: 1.2rem;
}/*# sourceMappingURL=TextOverflow.css.map */