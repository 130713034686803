.loader-wrapper {
  position: relative;
  height: 150px;
}
.loader-wrapper svg {
  width: 100% !important;
  height: 100% !important;
}
.loader-wrapper .inner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11111;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  flex-direction: column;
}
.loader-wrapper .message {
  margin-top: 15px;
}/*# sourceMappingURL=Loader.css.map */