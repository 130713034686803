section.landing-page {
  position: relative;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 15px;
  // padding: 30px 15px;
  // height: 100%;
  .card {
    overflow-x: hidden;
  }
  .mui-stepper-container {
    width: calc(100% + 40px);
    margin-left: -20px;
    // padding-bottom: 15px;
    box-shadow: 0 1px 1px 0px #00000013;
    // width: 100%;
    // margin-bottom: 30px;
    // border-radius: 100px;
    // border-width: 1px !important;
  }

  .landing-page__body {
    position: relative;
    // padding: 10px 10px;
    // max-width: 620px;
    // max-width: 580px;
    // min-height: 300px;
    margin: 0 auto;
    .wizard-content-area {
      margin-top: 20px !important;
    }
    form {
      margin-top: 15px;
    }
    button#form-submit {
      margin-top: 30px;
    }
    .existing-user-container {
      display: flex;
      align-items: center;
      justify-content: center;
      // padding-top: 15px;
      margin-top: 40px;
      border-top: 1px solid #80808040;
      padding-top: 30px;
      button {
        margin-left: 5px;
      }
      .underline-ghost-button {
        background-color: transparent;
        color: #235685;
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  section.landing-page {
    padding: 15px 10px;
    .header-content {
      h1 {
        font-size: 24px;
        line-height: 2rem;
      }
      p {
        font-size: 0.8rem;
        line-height: 22px;
        margin-top: 5px;
        // padding: 0 4px;
      }
    }
    .landing-page__body {
      padding: 0 !important;
    }
  }
}
