.multiple-unit-card-item {
  position: relative;
  border-bottom: none;
}
.multiple-unit-card-item#property-unit .multiple-item-card {
  border: 2px solid rgba(28, 69, 106, 0.2117647059);
}
.multiple-unit-card-item#property-unit .MuiAccordionSummary-content {
  margin: 8px 0 !important;
}
.multiple-unit-card-item#property-unit .MuiAccordionDetails-root {
  background-color: rgba(28, 69, 106, 0.0196078431);
}
.multiple-unit-card-item#property-unit .file-upload-box {
  background-color: #ffffff;
}
.multiple-unit-card-item.additional {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1.7px dashed rgba(39, 42, 44, 0.2);
}
.multiple-unit-card-item article.multiple-item-card {
  position: relative;
  border: 1.5px dotted rgba(128, 128, 128, 0.3803921569);
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
  border-left: 2px solid var(--blue);
}
.multiple-unit-card-item article.multiple-item-card #property-unit-all-fields-accordian .MuiAccordionSummary-root {
  background-color: #ffffff;
  position: relative;
  min-height: 42px;
  border-bottom: 1px solid rgba(28, 69, 106, 0.2117647059);
}
.multiple-unit-card-item article.multiple-item-card #property-unit-all-fields-accordian .MuiAccordionSummary-content {
  margin: 10px 0;
}
.multiple-unit-card-item article.multiple-item-card #property-unit-all-fields-accordian .MuiAccordionDetails-root {
  padding-top: 0;
}
.multiple-unit-card-item article.multiple-item-card #property-unit-all-fields-accordian .multiple-item-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.multiple-unit-card-item article.multiple-item-card #property-unit-all-fields-accordian .multiple-item-card__header .header__title {
  display: flex;
  align-items: center;
  text-align: left;
  font-family: var(--inter);
}
.multiple-unit-card-item article.multiple-item-card #property-unit-all-fields-accordian .multiple-item-card__header .header__title h4 span {
  color: rgba(13, 13, 13, 0.7725490196) !important;
  font-family: inherit;
  line-height: 1.2rem !important;
  font-size: 0.75rem !important;
}
.multiple-unit-card-item article.multiple-item-card #property-unit-all-fields-accordian .multiple-item-card__header .header__title .index-value {
  font-family: inherit !important;
}
.multiple-unit-card-item article.multiple-item-card #property-unit-all-fields-accordian .multiple-item-card__header button.remove {
  border: none;
  color: var(--blue);
  border: 1px solid var(--blue);
  font-size: 0.7rem;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  z-index: 1111111111;
}
.multiple-unit-card-item article.multiple-item-card #property-unit-all-fields-accordian .multiple-item-card__body {
  position: relative;
  margin-top: 0px !important;
  padding-top: 10px;
}
.multiple-unit-card-item article.multiple-item-card #property-unit-all-fields-accordian .multiple-item-card__body .static-form-label > label {
  font-weight: 500;
  font-size: 0.75rem;
}/*# sourceMappingURL=PropertyUnitCard.css.map */