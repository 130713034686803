.input-save-icon {
  display: flex;
  border: 1px solid rgba(28, 70, 106, 0.3098039216);
  margin-left: 3px;
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  border-radius: 50px;
  z-index: 111111;
  margin-top: -10px;
}
.input-save-icon.row {
  flex-direction: row;
  margin-left: 0;
}
.input-save-icon.row button {
  padding: 2px 4px !important;
}
.input-save-icon.row button#save {
  border-top-right-radius: 0px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.input-save-icon.row button#reset {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 0px;
}
.input-save-icon button {
  display: flex;
  padding: 2px 1px;
  align-items: center;
  background: rgba(28, 69, 106, 0.1490196078);
  border-radius: 0;
}
.input-save-icon button:hover {
  background: rgba(204, 219, 232, 0.1490196078);
}
.input-save-icon button#save {
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
}
.input-save-icon button#reset {
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}
.input-save-icon button svg {
  display: block;
  color: var(--blue);
  height: 14px;
  width: 14px;
}/*# sourceMappingURL=InputSave.css.map */