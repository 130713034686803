.landing-page__header {
  position: relative;
  h1 {
    // font-family: var(--jost);
    color: #3a3a3a;
    font-weight: 800;
    font-size: 26px;
    line-height: 2.2rem;
  }
  p {
    color: #707070;
    font-size: 0.9rem;
    line-height: 24px;
    // margin-top: 5px;
  }
  .logos-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 420px;
    margin: 0 auto;
    .combined {
      display: flex;
      align-items: center;
      #flat-iron-logo {
        margin-left: 0;
        padding-right: 10px;
        margin-right: 10px !important;
        border-right: 1px solid rgba(128, 128, 128, 0.372);
      }
      .auction-logo-wrapper {
        // height: 80px;
        svg {
          display: block;
          height: 20px;
          width: 100%;
          // width: 100%;
          // height: 100%;
        }
      }
    }
    .logo-wrapper {
      width: 120px;
      margin: 0 15px;
      svg {
        height: 100%;
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
  }
  .header-content {
    text-align: center;
    padding: 30px 0 0;
    // padding: 15px 0 0;
  }
}
