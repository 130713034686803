.success-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .success-message {
    margin-top: 15px;
  }
  span {
    font-weight: bold;
  }
  .checkmark {
    width: 72px !important;
    // margin-bottom: 30px;
  }
}
