#form-signature {
  position: relative;
  #signature-wrapper {
    position: relative;
    width: 100%;
    height: 160px;
    canvas {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      // height: 160px;
    }
    .signature-line {
      background-color: #bbbbbb;
      height: 2px;
      position: absolute;
      bottom: 35px;
      left: 50%;
      width: 90%;
      transform: translateX(-50%);
      .cross {
        position: absolute;
        left: 0;
        // height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 00px;
        svg {
          height: 28px;
          width: 28px;
          fill: #bbbbbb;
        }
      }
    }
  }
  .clear-signature-button {
    display: flex;
    justify-content: flex-end;
    #clear {
      padding: 2px;
      text-decoration: underline;
      font-size: 0.7rem;
      // background-color: rgb(235, 235, 235);
    }
  }
}
