#flat-iron-logo.logo-wrapper {
  width: 120px;
  display: flex;
  // margin: 0 auto;
  justify-content: center;
  svg {
    height: 100%;
    width: 100%;
  }
  img {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  #flat-iron-logo.logo-wrapper {
    width: 105px;
  }
}
