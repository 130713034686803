.card {
  position: relative;
  background-color: #fff;
  backdrop-filter: blur(3px);
  box-shadow: 0px 2px 4px 1px #0000001c;
  width: 100%;
  max-width: 720px;
  border-radius: 8px;
  padding: 20px;
  // padding: 40px 20px;
}
