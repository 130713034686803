.svg-icon-wrapper {
  display: flex;
  justify-content: center;
  transition: 100ms;
}
.svg-icon-wrapper.active {
  transform: rotate(180deg);
}
.svg-icon-wrapper svg {
  width: 100%;
}/*# sourceMappingURL=SvgWrapper.css.map */