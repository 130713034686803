.card {
  position: relative;
  background-color: #fff;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1098039216);
  width: 100%;
  max-width: 720px;
  border-radius: 8px;
  padding: 20px;
}/*# sourceMappingURL=Card.css.map */