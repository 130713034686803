.form-group {
  position: relative;
  padding: 5px 0;
  margin-top: 20px;
  text-align: left;

  .radio-field-options {
    padding-left: 10px;
  }

  &.no-margin {
    margin-top: 0;
  }

  .form-field {
    padding-left: 0;
    padding-right: 0;
  }

  &.bottom-border {
    border-bottom: 1px dashed #8080808c;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  &.error {
    #autofill-field {
      border: 1px solid var(--red) !important;
      border-radius: 6px;
    }
  }

  .form-group-title {
    font-size: 0.8rem;
    color: #235685;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.02rem;
    text-align: left;
  }
}
