$boxHeight: 80px;

#file-upload-field {
  .MuiInputLabel-root {
    // font-size: 0.75rem;
    font-weight: 500;
  }
}

#file-upload {
  .file-upload-inner-content {
    // height: $boxHeight;
    // margin-top: 10px;
    display: flex;
    width: 100%;
    text-align: center;
    .file-upload-box {
      position: relative;
      display: flex;
      flex-grow: 1;
      border-radius: 2px;
      background-color: #f7f7f7a3;
      border: 2px dashed #d4d4d4;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: 200ms;
      color: var(--blue);
      width: 100%;
      height: 100px;
      cursor: pointer;
      min-width: 0;
      &:hover {
        background-color: #e3eef8;
      }
      svg {
        fill: var(--gray);
        height: 20px;
        width: 20px;
      }
    }
    .file-upload__title {
      color: var(--gray);
      font-size: 0.68rem;
    }
  }
}
